import { useTranslation } from "react-i18next"
import { BannerSectionWrapper } from "./styles"
import { GoInfo } from "react-icons/go"
import { useRecoilValue } from "recoil"
import { userDetailsStore } from "@store/userDetail"
import { useGetActivePlan, useGetCheckOutRenewal } from "@services"
import { useEffect } from "react"

export default function BannerSection() {
	const userDetailsValue = useRecoilValue(userDetailsStore)
	const {
		t,
		i18n: { language },
	} = useTranslation()

	const { data: dataActivePlan } = useGetActivePlan(
		{
			lang: language,
		},
		{ enabled: language !== undefined }
	)
	const { data: checkOutRenewalData, refetch: refetchCheckOutRenewal } =
		useGetCheckOutRenewal(
			{ paymentType: "1" },
			{
				enabled: false,
			}
		)

	useEffect(() => {
		if (checkOutRenewalData?.result?.checkout_url) {
			window.open(checkOutRenewalData?.result?.checkout_url, "_self")
		}
	}, [checkOutRenewalData])

	return (
		<BannerSectionWrapper>
			<div className='containerMessage'>
				<span className='icon'>
					<GoInfo />
				</span>
				<span className='infoText'>
					{language === "ar"
						? userDetailsValue.result.popUpMessage.contentAr
						: userDetailsValue.result.popUpMessage.contentEn}
				</span>
			</div>
			<div className='containerActions'>
				<div className='buttonPay' onClick={() => refetchCheckOutRenewal()}>
					{t("MANAGE_PLAN.PAY_NOW")}
				</div>
			</div>
		</BannerSectionWrapper>
	)
}
