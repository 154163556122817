import React, { FC, ReactNode, useEffect } from "react"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { Logo } from "../../utils/image-consts"
import { useTranslation } from "react-i18next"
import SuccessIcon from "@assets/svg/success.svg?react"
import FailedIcon from "@assets/svg/failed.svg?react"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import { useGetAfterRenewalPayment } from "@services"
interface IProps {
	type: "loading" | "success" | "fail"
}

export default function AfterRenewalPaymentPage({ type }: IProps) {
	const icons = {
		success: <SuccessIcon />,
		fail: <FailedIcon />,
		loading: <CircularProgress />,
	}
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isUser = localStorage.getItem("token")

	const [params, setParams] = useSearchParams()

	const { data, isSuccess, isError } = useGetAfterRenewalPayment(
		{
			checkoutId: params.get("checkoutId")!,
		},
		{ enabled: params.get("checkoutId") !== undefined }
	)

	if (((isSuccess && !data?.result) || isError) && type == "loading") {
		return (
			<Navigate
				to={`/fail-payment-renewal?checkoutId=${params.get("checkoutId")}`}
				replace
			/>
		)
	}
	if (isSuccess && data?.result && type == "loading") {
		return (
			<Navigate
				to={`/success-payment-renewal?checkoutId=${params.get("checkoutId")}`}
				replace
			/>
		)
	}
	if (type == "loading") {
		return (
			<div className={"h-full flex items-center justify-center gap-4"}>
				<CircularProgress />
			</div>
		)
	}
	return (
		<Box
			width={"450px"}
			maxWidth={"calc(100vw - 64px)"}
			display={"flex"}
			gap={3}
			alignItems={"center"}
			flexDirection={"column"}
		>
			<Box
				component='img'
				src={Logo}
				width='143px'
				height='56px'
				sx={{ mb: 2, objectFit: "contain" }}
			/>
			<Box
				sx={{
					width: "100px",
					height: "100px",
					svg: {
						width: "100%",
						height: "100%",
					},
				}}
			>
				{icons[type]}
			</Box>
			<Box>
				<Typography textAlign={"center"} fontWeight={"bold"}>
					{t(`AFTER_PAYMENT.${type.toUpperCase()}_HEADER`, { count: 0 })}
				</Typography>
				<Typography textAlign={"center"}>
					{t(`AFTER_PAYMENT.${type.toUpperCase()}_DESCRIPTION`, { count: 0 })}
				</Typography>
			</Box>

			{type == "success" ? (
				<Button
					onClick={() =>
						navigate(!isUser ? "/login" : "/properties-management")
					}
					variant={"contained"}
					type={"submit"}
					fullWidth
				>
					{t(isUser ? "AFTER_PAYMENT.BACK_TO_LISTING" : "MENU.LOGIN")}
				</Button>
			) : (
				<Button
					onClick={() => navigate("/")}
					variant={"contained"}
					type={"submit"}
					fullWidth
				>
					{t("AFTER_PAYMENT.TRY_PAYMENT")}
				</Button>
			)}
		</Box>
	)
}
