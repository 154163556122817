import { UseQueryOptions, useQuery } from "@tanstack/react-query"
import { APIS_KEYS } from "../../apis-keys"
import {
	IPropsGetAfterRenewalPayment,
	IResponseGetAfterRenewalPayment,
} from "./types"
import type { AxiosInstance, AxiosResponse } from "axios"
import { useApi } from "../../useApi"
import { afterRenewalPaymentURL } from "../../../utils/constants"
async function getAfterRenewalPayment(
	{ ...rest }: IPropsGetAfterRenewalPayment,
	apiPrivate: AxiosInstance
) {
	try {
		const response: AxiosResponse<any> = await apiPrivate.get(
			`${afterRenewalPaymentURL}`,
			{
				params: rest,
			}
		)
		return response.data
	} catch (error: any) {
		console.log(error)
		throw new Error("something went wrong")
	}
}

export function useGetAfterRenewalPayment(
	data: IPropsGetAfterRenewalPayment,
	options?:
		| Omit<UseQueryOptions<any, unknown, any>, "queryKey" | "queryFn">
		| undefined
) {
	const { apiPrivate } = useApi()
	return useQuery({
		queryKey: [APIS_KEYS.GETAFTERRENEWALPAYMENT, ...Object.values(data)],
		queryFn: () => getAfterRenewalPayment(data, apiPrivate),
		...options,
	})
}
