import styled from "styled-components"

export const BannerSectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #feda15;
	padding: 14px 24px;
	margin: -24px;
	margin-bottom: 38px;
	gap: 24px;
	.containerMessage {
		display: grid;
		grid-template-columns: 26px 1fr;
		grid-template-rows: 1fr;
		gap: 8px;
		.icon {
			width: 26px;
			height: 26px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.infoText {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			color: #0f172a;
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	.containerActions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 24px;
		.buttonPay {
			font-size: 14px;
			font-weight: 400;
			line-height: 16.8px;
			padding: 10px 12px;
			color: #fff;
			background-color: #5b3fff;
			border-radius: 20px;
			white-space: nowrap;
			cursor: pointer;
		}
	}
`
