import {
  WebsiteBuilderGetDomain,
  WebsiteBuilderSaveDomain,
  WebsiteBuilderCheckAvailability,
  WebsiteBuilderGetProperties,
  WebsiteBuilderAddOrUpdatePorpretiesListing,
  WebsiteBuilderUploadLogo,
  WebsiteBuilderCreateOrUpdateWebsiteSettings,
  WebsiteBuilderGetWebsiteSettings,
  WebsiteBuilderUpdateVisability,
  WebsiteBuilderActivatePayments,
  UploadWebSiteBuilderPaymentsFiles,
  GetWebSiteBuilderPaymentStatus,
  ActiveOrDeActiveGatewayURL,
  GetTermsConditionsURL,
  UpdateTermsConditionsURL,
  UpdatePoliciesURL,
  SendTransferToCustomeDomainURL, 
  GetPolicyUrl
} from "../utils/constants";

import { useApi } from "./useApi";
import { useMutation, useQuery } from "@tanstack/react-query";

export type errorTypeWebsiteBuilder = {
  response: {
    data: { errorMessages: { messageAr: string; messageEn: string }[] };
  };
};

const useWebsiteBuilder = () => {
  const { apiPrivate } = useApi();
  const SendTransferToCustomeDomainApi = async () => {
    const result = await apiPrivate.post(
      `${SendTransferToCustomeDomainURL}`
    );
    return result.data?.result;
  };

  const WebsiteBuilderGetTermsConditionsApi = async (data: any) => {
    const result = await apiPrivate.get(
      `${GetTermsConditionsURL}?id=${data.id}`
    );
    return result.data?.result;
  };
  const WebsiteBuilderGetPolicyApi = async (id: any) => {
    const result = await apiPrivate.get(
      `${GetPolicyUrl}?id=${id}`
    );
    return result.data?.result;
  };

  const WebsiteBuilderUpdateTermsConditionsApi = async (data: any) => {
    const result = await apiPrivate.post(
      `${UpdateTermsConditionsURL}?id=${data.id}`,
      data
    );
    return result.data?.result;
  };

  const WebsiteBuilderUpdatePoliciesApi = async (data: any) => {
    const result = await apiPrivate.post(
      `${UpdatePoliciesURL}?id=${data.id}`,
      data
    );
    return result.data?.result;
  };
  // ---------------------------------------------------------------------------------------
  const WebsiteBuilderActivatePaymentsApi = async (data: any) => {
    const result = await apiPrivate.post(WebsiteBuilderActivatePayments, data);
    return result.data?.result;
  };
  const UploadWebSiteBuilderPaymentsFilesApi = async (body: any) => {
    const { data } = await apiPrivate.post(
      `${UploadWebSiteBuilderPaymentsFiles}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  };
  const ActivateOrDeactivatePaymentGetway = async (id = "") => {
    const result = await apiPrivate.get(
      ActiveOrDeActiveGatewayURL + `?id=${id}`
    );
    return result.data?.result;
  };
  const GetWebSiteBuilderPaymentStatusApi = async () => {
    const result = await apiPrivate.get(GetWebSiteBuilderPaymentStatus);
    return result.data?.result;
  };
  const getDomain = async () => {
    const result = await apiPrivate.get(WebsiteBuilderGetDomain);
    return result.data?.result;
  };

  const saveDomain = async (body: { name: string }) => {
    const { data } = await apiPrivate.post(`${WebsiteBuilderSaveDomain}`, body);
    return data;
  };

  const checkAvailability = async (body: { name: string }) => {
    const { data } = await apiPrivate.post(
      `${WebsiteBuilderCheckAvailability}`,
      body
    );
    return data;
  };

  const getProperties = async () => {
    const result = await apiPrivate.get(WebsiteBuilderGetProperties);
    return result.data?.result;
  };

  const addOrUpdatePropertiesListing = async (body: any) => {
    const { data } = await apiPrivate.post(
      `${WebsiteBuilderAddOrUpdatePorpretiesListing}`,
      body
    );
    return data;
  };

  const uploadLogo = async (body: any) => {
    const { data } = await apiPrivate.post(
      `${WebsiteBuilderUploadLogo}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return data;
  };
  const UpdateVisibilityApi = async (Id = "") => {
    const { data } = await apiPrivate.post(
      `${WebsiteBuilderUpdateVisability}?Id=${Id}`,
      {}
    );
    return data;
  };

  const createOrUpdateWebsiteSettings = async (body: {
    Id: number;
    logo: string;
    HeroSectionLogo: string;
    BrandColor: string;
    HeroSection: string;
    HeaderFontColor: string;
    PriceColor: string;
    MiddleColor: string;
    MiddleFontColor: string;
    FooterColor: string;
    FooterFontColor: string;
    FontStyle: string;
    HeroSectionTitleAr: string;
    HeroSectionTitleEn: string;
    HeroSectionSubtitleAr: string;
    HeroSectionSubtitleEn: string;
    AboutUsContentAr: string;
    AboutUsContentEn: string;
    TermsAndConditionsContentAr: string;
    TermsAndConditionsContentEn: string;
    FooterDescriptionAr: string;
    FooterDescriptionEn: string;
    Twitter: string;
    Instagram: string;
    Facebook: string;
    Snapchat: string;
    TikTok: string;
    Youtube: string;
    Linkedin: string;
    Telegram: string;
    Phone: string;
    Email: string;
    IsCustomizationSkiped: boolean;
    step: number;
  }) => {
    const { data } = await apiPrivate.post(
      `${WebsiteBuilderCreateOrUpdateWebsiteSettings}`,
      body
    );
    return data;
  };

  const getWebsiteSettings = async () => {
    const result = await apiPrivate.get(WebsiteBuilderGetWebsiteSettings);
    return result.data?.result;
  };

  // Query

  const getTermsQuery = (data: { id: number }) =>
    useQuery({
      enabled: true,
      queryKey: [GetTermsConditionsURL],
      queryFn: ({ queryKey }) => WebsiteBuilderGetTermsConditionsApi(data),
    });

  const getPolicyQuery = (data: { id: number }) =>
  useQuery({
    enabled: true,
    queryKey: [GetPolicyUrl,data?.id],
    queryFn: ({ queryKey }) => WebsiteBuilderGetPolicyApi(data?.id),
  });
    
  const getDomainQuery = () =>
    useQuery({
      enabled: true,
      queryKey: [WebsiteBuilderGetDomain],
      queryFn: ({ queryKey }) => getDomain(),
    });

  const getPropertiesQuery = () =>
    useQuery({
      enabled: true,
      queryKey: [WebsiteBuilderGetProperties],
      queryFn: ({ queryKey }) => getProperties(),
    });

  const getWebsiteSettingsQuery = () =>
    useQuery({
      enabled: true,
      queryKey: [WebsiteBuilderGetWebsiteSettings],
      queryFn: ({ queryKey }) => getWebsiteSettings(),
    });

  const getPaymentStatusQuery = () =>
    useQuery({
      enabled: true,
      queryKey: [GetWebSiteBuilderPaymentStatus],
      queryFn: ({ queryKey }) => GetWebSiteBuilderPaymentStatusApi(),
    });

  // Mutate

  const saveDomainMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => saveDomain(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const checkAvailabilityMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: () => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => checkAvailability(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(),
    });

  const addOrUpdatePropertiesListingMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => addOrUpdatePropertiesListing(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const uploadLogoMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => uploadLogo(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const createOrUpdateWebsiteSettingsMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => createOrUpdateWebsiteSettings(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });
  const UpdateVisibilityApiMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => UpdateVisibilityApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const uploadPaymentAttachmentMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => UploadWebSiteBuilderPaymentsFilesApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  // ------------------------------------------------------------------------------
  const GetTermsConditionsMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => WebsiteBuilderGetTermsConditionsApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const UpdateTermsConditionsMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => WebsiteBuilderUpdateTermsConditionsApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const UpdatePoliciesMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => WebsiteBuilderUpdatePoliciesApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const SendTransferToCustomeDomainMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: () => SendTransferToCustomeDomainApi(),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });
  // ------------------------------------------------------------------------------

  const activatePaymentMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => WebsiteBuilderActivatePaymentsApi(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  const activateOrDeactivatePaymentOptionMutate = ({
    onSuccess,
    onError,
  }: {
    onSuccess?: () => void;
    onError?: (error: errorTypeWebsiteBuilder) => void;
  }) =>
    useMutation({
      mutationFn: (body: any) => ActivateOrDeactivatePaymentGetway(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error: errorTypeWebsiteBuilder) => onError && onError(error),
    });

  return {
    getDomainQuery,
    getPropertiesQuery,
    getWebsiteSettingsQuery,
    saveDomainMutate,
    checkAvailabilityMutate,
    addOrUpdatePropertiesListingMutate,
    uploadLogoMutate,
    createOrUpdateWebsiteSettingsMutate,
    UpdateVisibilityApiMutate,
    getPaymentStatusQuery,
    activatePaymentMutate,
    uploadPaymentAttachmentMutate,
    activateOrDeactivatePaymentOptionMutate,
    GetTermsConditionsMutate,
    UpdateTermsConditionsMutate,
    getTermsQuery,
    UpdatePoliciesMutate,
    SendTransferToCustomeDomainMutate,
    getPolicyQuery
  };
};

export { useWebsiteBuilder };
