import { loginURL, registerURL } from '../utils/constants';
import { useApi } from './useApi';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { IIncome, IRegistration, ISurvey } from '@interfaces';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

const useAuth = () => {
  const { apiPublic, apiPrivate } = useApi();

  const navigate = useNavigate();

  const login = async ({ email, password }: { email: string; password: string }) => {
    const {
      data: { result },
    } = await apiPublic.post(loginURL, { email: email, password: password });

    const { exp } = jwtDecode(result?.token);
    window.localStorage['userName'] = result?.user?.fullName;
    window.localStorage['userId'] = result?.user?.id;
    window.localStorage['email'] = result?.user?.email;
    window.localStorage['gathernToken'] = result?.user?.gathernToken;
    window.localStorage['channexChannelId'] = result?.user?.channexChannelId;

    // TODO, get rid of all above info and keep below
    window.localStorage['user'] = JSON.stringify(result?.user);
    window.localStorage['token'] = result?.token;
    window.localStorage['exp'] = `${exp! * 1000}`;
    window.localStorage['showWebsiteBuikder'] = result?.showWebsiteBuikder? 'true' : 'false';

    return result;
  };

  const getUserInfo = () => {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
  };

  const isRoleUser = () => {
    return getUserInfo()?.role?.roleName == 'User';
  };
  const isRoleOwner = () => {
    return getUserInfo()?.role?.roleName == 'User';
  };

  const isUserActive = () => {
    return getUserInfo()?.hasActiveSubscription;
  };

  const showInactiveModal = () => {
    if (getUserInfo().trialDays && getUserInfo().trialDays > 0) {
      return false;
    }
    return !isUserActive();
  };
  const register = async (body: IRegistration & ISurvey): Promise<{ id: number }> => {
    const {
      data: { result },
    } = await apiPublic.post<{ result: { id: number } }>(registerURL, {
      ...body,
      isAgreeTerms: true,
    });
    return result;
  };

  const registerMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (error: any) => void }) =>
    useMutation({
      mutationFn: (body: IRegistration & ISurvey) => register(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(error),
    });

  const loginMutate = ({ onSuccess, onError }: { onSuccess?: () => void; onError?: (error: any) => void }) =>
    useMutation({
      mutationFn: (body: { email: string; password: string }) => login(body),
      onSuccess: (data) => onSuccess && onSuccess(),
      onError: (error) => onError && onError(error),
    });

  const logout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return {
    loginMutate,
    register,
    logout,
    registerMutate,
    getUserInfo,
    isUserActive,
    showInactiveModal,
    isRoleUser,
    isRoleOwner,
  };
};

export { useAuth };
